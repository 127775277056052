@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';

.how {
    padding: 0 5%;

    .how-body {
        display: flex;
        justify-content: center;
        margin-top: 5%;
        height: 150vh;

        .img {
            width: 23%;
            border-radius: 10px;
            // margin-top: 5%;
            // padding-left: 5%;

            img {
                width: 100%;
                border-radius: 10px;
                // box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                filter: drop-shadow(2px 2px 5px rgba($color: #000000, $alpha: 0.4));
            }
        }



        .how-card {
            padding: 0 5% 5% 5%;
            width: 70%;
            // margin-left: 5%;

            .how-card-text {


                &:last-child {
                    margin-top: 35%;
                    margin-bottom: 30%;
                }

                h3 {
                    text-transform: capitalize;
                    font-weight: bold;
                    color: $blue;
                    text-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.2);
                    margin-bottom: 5%;
                    font-size: 35px;

                }

                p {
                    font-size: 20px;
                    line-height: 180%;
                    margin-bottom: 5%;
                }

                .how-btn {
                    display: flex;
                    padding: 0;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 3%;
                        font-size: 20px;
                        border: 1px solid #fff;
                        padding: 2%;
                        border-radius: 10px;
                        width: 50%;
                        box-shadow: 2px 2px 10px rgba($color: $blue, $alpha: 0.44);
                        background: $blue;

                        color: #fff;

                        img {
                            width: 40px;
                            height: 40px;
                            margin-right: 5%;
                        }


                        &:last-child {
                            margin-right: 0;
                        }

                    }
                }
            }


        }

    }
}

.how-mobile {
    padding: 0 5%;
    display: none;

    .how-mobile-body {

        .how-mobile-card {

            width: 100%;

            .img {
                width: 100%;
                border-radius: 10px;


                img {
                    width: 60%;
                    border-radius: 10px;
                    filter: drop-shadow(2px 2px 5px rgba($color: #000000, $alpha: 0.4));
                    display: block;
                    margin: auto;
                }
            }

            .how-mobile-card-text {
                padding: 5%;

                h3 {
                    text-transform: capitalize;
                    font-weight: bold;
                    color: $blue;
                    text-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.2);
                    margin-bottom: 5%;
                    font-size: 20px;

                }

                p {
                    font-size: 16px;
                    line-height: 180%;
                    margin-bottom: 5%;
                }

                .how-mobile-btn {
                    display: flex;
                    padding: 0;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 3%;
                        font-size: 16px;
                        border: 1px solid #fff;
                        padding: 2%;
                        border-radius: 10px;
                        width: 50%;
                        box-shadow: 2px 2px 10px rgba($color: $blue, $alpha: 0.44);
                        background: $blue;

                        color: #fff;

                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 5%;
                        }


                        &:last-child {
                            margin-right: 0;
                        }

                    }
                }
            }


        }

    }
}

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .how {
        display: none;
    }

    .how-mobile {
        display: block;
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .how {
        display: none;
    }

    .how-mobile {
        display: block;
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .how {
        display: none;
    }

    .how-mobile {
        display: block;
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}