@import 'variables.scss';
@import 'mixin.scss';

.ql-snow h1 {
    font-size: 2em !important;
}

.ql-snow h2 {
    font-size: 1.6em !important;
}

.ql-snow h3 {
    font-size: 1.2em !important;
}

/*===============================================================
# Dashboard
===============================================================*/
.dashboard {
    display: flex;
    justify-content: flex-end;
}

.dashboard-app {
    width: 75%;
    padding: 2%;

    h3 {
        text-align: center;
        font-size: 4vw;
        text-transform: capitalize;
        color: $blue;
        text-align: center;
        margin: 5% 0;
    }
}

/*===============================================================
# Login
===============================================================*/

.admin-login {
    // @include flex;
    // background: $blue;
    // background: url("../Images/Untitled\ design.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    width: 100%;
    padding: 5% 0 0 0;
    color: #fff;

    .admin-login-content {
        // width: 40%;
        // margin: auto;
        @include flex;
        align-items: center;


        img {
            width: 50%;
            object-fit: contain;
            margin-right: 5%;
        }

        form {
            width: 30%;
            display: flex;
            flex-direction: column;
            padding: 4%;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);

            img {
                width: 70%;
                margin: auto;

            }


            h5 {
                font-size: 25px;
                text-align: center;
                margin: 10% 0;
                font-weight: 500;
                color: $blue;
                text-transform: capitalize;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 10%;
                    top: 60%;
                    transform: translate(-50%, -50%);
                    height: 1px;
                    width: 20%;
                    background: $blue;

                }

                &::after {
                    content: "";
                    position: absolute;
                    right: -10%;
                    top: 60%;
                    transform: translate(-50%, -50%);
                    height: 1px;
                    width: 20%;
                    background: $blue;

                }

            }

            input {
                font-size: 16px;
                margin-bottom: 5%;
                border: none;
                padding: 3%;
                border-radius: 5px;
                border: none;
                outline: none;
                color: $blue;
                box-shadow: inset 2px 2px 5px rgba($color: $blue, $alpha: 0.5);

                &:focus {
                    border-bottom: 2px solid $blue;
                }
            }

            .login-button {
                background: $blue;
                // border: 2px solid #fff;
                color: #fff;
                font-size: 1.5vw;
                margin-top: 5%;
                border-radius: 10px;
                box-shadow: inset 2px 2px 5px rgba($color: #fff, $alpha: 0.5);

            }
        }
    }
}

/*===============================================================
# Admin Dashbaord
===============================================================*/
.dashboard-nav {
    height: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 25%;
    background: $blue;
    color: #ffff;
    padding: 3%;
    position: relative;


    .menu {
        fill: #fff;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        display: none;
    }

    .a-logo {
        background: #fff;
        display: block;
        margin-bottom: 2%;
        padding: 8%;
        border-radius: 5px;
        width: 80%;
        // height: 20%;
        margin: auto;
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.7);

        img {
            width: 100%;
            // height: 100%;
            object-fit: contain;
            display: block;
            margin: auto;
        }
    }

    .dashboard-nav-list,
    .dashboard-nav-mobile-list {
        @include flex;
        flex-direction: column;
        margin-top: 10%;
        overflow-x: hidden;
        overflow-y: hidden;
        // height: 50vh;

        .dashboard-nav-item {
            margin-bottom: 5%;
            font-size: 1.2vw;
            text-transform: capitalize;
            padding: 3%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 15px;
                height: 15px;
                margin-right: 5%;
                fill: #fff;
            }
        }

        button {
            width: 90%;
            margin: 0 5%;
            border: none;
            border-top: 1px solid #fff;
            padding: 5%;
            position: absolute;
            bottom: 0;
            right: 0;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            p {
                margin-bottom: 0;
                font-size: 1.5vw;
                color: #fff;
            }

            svg {
                width: 15px;
                height: 15px;
                margin-left: 5%;
                fill: #fff;
            }
        }
    }

    .dashboard-nav-mobile-list {
        display: none;
    }

    .admin-blog {
        margin-bottom: 5%;
        font-size: 1.2vw;
        text-transform: capitalize;
        padding: 3%;

        svg {
            width: 15px;
            height: 15px;
            margin-right: 5%;
            fill: #fff;
        }


        &:hover {
            .admin-blog-nav {
                display: flex;
                transition: .5s all ease;
            }
        }

        .admin-blog-nav {
            @include flex;
            display: none;
            flex-direction: column;
            color: #fff;
            margin-top: 5%;
            padding-top: 5%;
            padding-left: 5%;
            border-top: 1px solid #fff;
            height: 100%;

            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 2%;
                margin: 0;
                font-size: 1.2vw;

                &:hover {
                    transform: scale(1.1) translateX(15px);
                    transition: .5s all ease;
                    background: transparent;
                }
            }
        }
    }


}

/*===============================================================
# Blog- Create
===============================================================*/
.blog-create {

    form {
        display: flex;
        flex-direction: column;
        margin: 5% 0;
        // box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        padding: 2%;

        .__input {
            display: flex;
            justify-content: space-between;

            label {
                font-size: 1.2vw;
                text-transform: capitalize;
                width: 30%;
            }

            .checkbox-buttons {
                width: 70%;
                border: none;
                border-radius: 5px;
                color: #000;
                width: 100%;
                margin-bottom: 2%;
                display: flex;
                align-items: stretch;
                flex-wrap: wrap;
                border: 1px solid rgba($color: #000000, $alpha: 0.2);
                padding: 2%;

                .checkbox-body {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    width: calc(100% / 2);

                    input {
                        font-size: 1vw;
                        width: 10px;
                        height: 10px;
                        margin-right: 5%;
                    }

                    label {
                        font-size: 1vw;
                        width: 90%;
                    }
                }


            }
        }

        .input {
            width: 100%
        }

        input,
        textarea {
            font-size: 1vw;
            border: none;
            padding: 1% 2%;
            border-radius: 5px;
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            outline: none;
            color: #000;
            width: 100%;
            margin-bottom: 2%;
        }

        .tags {
            font-size: 1vw;
            border: none;
            border-radius: 5px;
            outline: none;
            color: #000;
            width: 100%;
            margin-bottom: 2%;
        }

        button {
            background: $blue;
            color: #fff;
            border: none;
            font-size: 1.1vw;
            padding: 1%;
            border-radius: 5px;
            margin-top: 10%;
            width: 100%;
        }
    }


}

/*===============================================================
# Blog- List
===============================================================*/
.blog-list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 5% 0;

    .blog-card {
        width: calc(100% / 3.2);
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        margin: 1%;
        position: relative;
        padding: 1%;

        img {
            width: 100%;
            height: 50%;
            object-fit: cover;
        }

        .blog-card-text {
            margin-bottom: 10%;
            padding: 3%;

            p {
                font-size: 0.9vw;
                font-weight: bold;
            }

            span {
                font-size: 0.9vw;
                color: $blue;
                display: block;
                margin-bottom: 5%;

                svg {
                    width: 15px;
                    height: 15px;
                    fill: $blue;
                    margin-right: 5%;
                }
            }
        }

        .blog-card-button {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            position: absolute;
            bottom: 1%;
            padding: 3%;
            left: 0;
            width: 100%;

            a,
            button {
                width: calc(100% / 2.1);
                border: none;
                padding: 2%;
                border-radius: 5px;
                font-size: 1vw;
                text-transform: capitalize;
                text-align: center;
                margin: 1%;
                background: $blue;
                color: #fff;

                &:hover {
                    background: transparent;
                    color: #000;
                    transition: .2s all ease-in-out;
                    border: 1px solid $blue;
                }
            }
        }
    }

    p {
        font-size: 16px;
    }
}

/*===============================================================
# Blog- Category
===============================================================*/
.blog-category {
    // @include flex;

    .blog-create {
        // width: 40%;
        // padding: 2%;
        box-shadow: $boxShadow;
        background: $blue;
        color: #fff;

        form {
            margin-bottom: 0;

            .__input {
                flex-direction: column;

                label {
                    width: 100%;
                    margin-bottom: 2%;
                }
            }

            button {
                margin-top: 1%;
                border: 2px solid #fff;
            }
        }
    }

    .blog-category-present {
        // width: 60%;
        // padding: 5%;
        @include flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 5%;

        .b-p {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: calc(100% / 2.03);
            box-shadow: $boxShadow;
            padding: 2%;
            margin-bottom: 1%;
            border: 1px solid rgba($color: $blue, $alpha: 0.2);

            &:nth-child(odd) {
                margin-right: 1%;
                margin-bottom: 1%;
            }

            p {
                font-size: 1.1vw;
                margin: 0;
            }

            button {
                text-transform: capitalize;
                border: none;
                color: #fff;
                background: $blue;
                padding: 1% 3%;
                font-size: 1.1vw;
                border-radius: 5px;
            }

        }
    }

}

/*===============================================================
# Career - Opportunities for students
===============================================================*/
.o-f-s {
    padding: 5%;

    form {
        .input {
            margin: 1% 0;
            display: flex;

            label {
                width: 20%;
                font-size: 1.2vw;
            }

            input,
            select {
                width: 80%;
                padding: 1%;
                border-radius: 5px;
                border: none;
                outline: none;
                border: 1px solid rgba($color: #000000, $alpha: 0.2);
                font-size: 1vw;
            }

            .job {
                width: 80%;
                border-radius: 5px;
                border: none;
                outline: none;
                font-size: 1vw;
            }

        }

        button {
            width: 100%;
            float: right;
            background: $blue;
            color: #fff;
            border: none;
            padding: 1% 5%;
            border-radius: 5px;
            margin-top: 5%;
        }
    }
}

/*===============================================================
# Career - Opportunities for students - Edit
===============================================================*/
.student-edit {
    margin: 5% 0;
    display: flex;
    flex-wrap: wrap;

    .student-edit-card {
        width: calc(100% / 3.2);
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        margin: 1%;
        padding: 2% 3%;
        position: relative;

        .student-edit-card-text {
            margin-bottom: 10%;

            h3 {
                font-size: 1.2vw;
                text-align: left;
                padding: 5% 0;
            }

            p {
                font-size: 1.1vw;
                font-weight: bold;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
                color: $blue;
            }

            ul {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-bottom: 20%;
                padding-left: 0;

                li {
                    font-size: 1vw;
                    font-weight: 400;
                    margin: 1%;
                    text-align: center;
                    text-transform: capitalize;

                    &:nth-child(1) {
                        font-weight: 500;
                    }
                }
            }

        }

        .student-edit-card-button {
            display: flex;
            justify-content: center;
            align-items: stretch;
            position: absolute;
            bottom: 5%;
            left: 0;
            width: 100%;

            a,
            button {
                width: calc(100% / 2.5);
                border: none;
                padding: 2%;
                border-radius: 5px;
                font-size: 1vw;
                text-transform: capitalize;
                text-align: center;
                margin: 1%;
                background: $blue;
                color: #fff;

                &:hover {
                    background: transparent;
                    color: #000;
                    transition: .2s all ease-in-out;
                    border: 1px solid $blue;
                }
            }
        }
    }
}

/*===============================================================
# Contact DB
===============================================================*/
.contact-form {
    table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px auto;
    }

    /* Zebra striping */
    tr:nth-of-type(odd) {
        background: #eee;
    }

    th {
        background: $blue;
        color: white;
        font-weight: bold;
    }

    td,
    th {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-size: 1.1vw;
        height: 5vh;
    }

    td {
        background: #fff;

    }


    .cov {
        max-height: 50px;
        height: 5vh;
        overflow-y: auto;
    }
}

.export {
    background: $blue;
    border: none;
    padding: 1% 4%;
    border-radius: 4px;
    color: #fff;
    text-transform: capitalize;
    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
    margin-top: 5%;
}

/*===============================================================
# Reset Password
===============================================================*/
.admin-settings {
    display: flex;
    flex-direction: column;
    margin: 10% auto 0 auto;
    width: 80%;


    input {
        font-size: 1.2vw;
        border: none;
        padding: 1% 2%;
        border-radius: 5px;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        outline: none;
        color: #000;
        width: 100%;
        margin-bottom: 1%;
    }

    button {
        background: $blue;
        color: #fff;
        border: none;
        font-size: 1.3vw;
        padding: 1%;
        border-radius: 5px;
        margin-top: 1%;
        width: 100%;
    }
}

/*===============================================================
# Media Queries
===============================================================*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .admin-login {
        padding: 40% 5%;

        .admin-login-content {

            img {
                display: none;
            }

            form {
                width: 100%;
                margin: auto;

                img {
                    width: 50%;
                    display: block;
                }

                h5 {
                    font-size: 5.5vw;
                }

                input {
                    font-size: 3.5vw;
                }

                .login-button {
                    font-size: 4vw;
                }
            }
        }
    }

    .dashboard {
        flex-direction: column;
    }

    .dashboard-nav {
        height: 10vh;
        width: 100%;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        z-index: 69999;

        .menu {
            display: block;
        }

        .a-logo {
            padding: 2% 5%;
            width: 40%;
            margin: auto 0;
        }

        .dashboard-nav-list {
            display: none;
        }

        .dashboard-nav-mobile-list {
            display: block;
            margin: 0;
            width: 70%;
            height: 100%;
            padding: 0 5%;
            position: fixed;
            right: 0;
            top: 0;
            background: #fff;
            z-index: 69999;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

            .close {
                fill: $blue;
                margin-left: auto;
                position: absolute;
                right: 5%;
                top: 2%;
                display: block;
                width: 30px;
                height: 30px;

            }

            .dashboard-nav-item {
                font-size: 4vw;
                color: $blue;

                svg {
                    fill: $blue;
                }
            }

            button {
                border-top: 1px solid $blue;

                p {
                    color: $blue;
                    font-size: 4vw;
                }

                svg {
                    fill: $blue;

                }
            }
        }

        .bd {
            margin-top: 30%;
        }

        .admin-blog {
            color: $blue;
            font-size: 4vw;
            display: block;

            svg {
                fill: $blue;
            }



            .admin-blog-nav {
                display: none;
                color: $blue;
                height: 15%;
                border-top: 1px solid $blue;

                a {
                    font-size: 3.5vw;

                }
            }
        }


    }

    .dashboard-app {
        width: 100%;
        margin-top: 30%;

        h3 {
            font-size: 5vw;
            font-weight: bold;
            color: $blue;
        }
    }

    .blog-list {
        .blog-card {
            width: 80%;
            margin: 3% auto;

            .blog-card-text {
                p {
                    font-size: 3vw;
                }

                span {
                    font-size: 2.5vw;
                }
            }

            .blog-card-button {

                a,
                button {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .blog-create form {
        .__input {
            flex-direction: column;

            label {
                font-size: 3.5vw;
                width: 100%;
                margin-bottom: 2%;
            }

            .checkbox-buttons {
                .checkbox-body {
                    label {
                        font-size: 2.5vw;
                    }
                }
            }

            .b__input {
                span {
                    font-size: 1.8vw;
                }
            }

            select {
                font-size: 3vw;
                margin-bottom: 4%;
            }
        }

        input {
            font-size: 3vw;
        }

        button {
            font-size: 3.5vw;
        }
    }

    .blog-category {
        .blog-category-present {
            .b-p {
                width: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                p,
                button {
                    font-size: 3vw
                }


            }
        }
    }

    .o-f-s {
        form {
            .input {
                flex-direction: column;

                label {
                    width: 100%;
                    font-size: 3.5vw;
                    margin-bottom: 2%;
                    margin-top: 5%;
                }

                input {
                    width: 100%;
                    font-size: 3vw;
                }

                .job {
                    font-size: 3vw;
                    width: 100%;
                }
            }
        }
    }

    .student-edit {
        .student-edit-card {
            width: calc(100% / 2.1);

            .student-edit-card-text {
                p {
                    font-size: 3.5vw;
                }

                h3 {
                    font-size: 3vw;
                }

                ul {
                    li {
                        font-size: 2.5vw;
                    }
                }
            }

            .student-edit-card-button {

                button,
                a {
                    font-size: 2.5vw
                }
            }
        }
    }

    .ql-container.ql-snow {
        height: 70%;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 20px;
        width: 22px;
        margin-top: 0;
    }

    .admin-settings {

        input,
        button {
            font-size: 3vw;
        }
    }

    .contact-form {
        table {
            margin: 5% 0;
            width: 50vh;

            thead {
                tr {
                    th {
                        font-size: 3vw;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    .admin-login {
        padding: 5% 5%;

        .admin-login-content {
            // width: 90%;

            form {
                width: 50%;
                img {
                    width: 50%;
                }

                h5 {
                    font-size: 5.5vw;

                    &::after,&::before{
                        display: none;
                    }
                }

                input {
                    font-size: 3.5vw;
                }

                .login-button {
                    font-size: 4vw;
                }
            }
        }
    }

    .dashboard {
        flex-direction: column;
    }

    .dashboard-nav {
        height: 15vh;
        width: 100%;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        z-index: 69999;

        .menu {
            display: block;
        }

        .a-logo {
            padding: 2% 3%;
            width: 30%;
            margin: auto 0;
        }

        .dashboard-nav-list {
            display: none;
        }

        .dashboard-nav-mobile-list {
            display: block;
            margin: 0;
            width: 70%;
            height: 100%;
            padding: 0 5%;
            position: fixed;
            right: 0;
            top: 0;
            background: #fff;
            z-index: 69999;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

            .close {
                fill: $blue;
                margin-left: auto;
                position: absolute;
                right: 5%;
                top: 2%;
                display: block;
                width: 30px;
                height: 30px;

            }

            .dashboard-nav-item {
                font-size: 3vw;
                color: $blue;

                svg {
                    fill: $blue;
                }
            }

            button {
                border-top: 1px solid $blue;

                p {
                    color: $blue;
                    font-size: 4vw;
                }

                svg {
                    fill: $blue;

                }
            }
        }

        .bd {
            margin-top: 30%;
        }

        .admin-blog {
            color: $blue;
            font-size: 3vw;
            display: block;

            svg {
                fill: $blue;
            }



            .admin-blog-nav {
                display: none;
                color: $blue;
                height: 15%;
                border-top: 1px solid $blue;

                a {
                    font-size: 2.5vw;

                }
            }
        }


    }

    .dashboard-app {
        width: 100%;
        margin-top: 25%;

        h3 {
            font-size: 5vw;
            font-weight: bold;
            color: $blue;
        }
    }

    .blog-list {
        .blog-card {
            width: calc(100% / 2.1);

            .blog-card-text {
                p {
                    font-size: 2.5vw;
                }

                span {
                    font-size: 2.5vw;
                }
            }

            .blog-card-button {

                a,
                button {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .blog-create form {
        .__input {
            flex-direction: column;

            label {
                font-size: 3vw;
                width: 100%;
                margin-bottom: 2%;
            }

            .checkbox-buttons {
                .checkbox-body {
                    label {
                        font-size: 2vw;
                    }
                }
            }

            .b__input {
                span {
                    font-size: 1.8vw;
                }
            }

            select {
                font-size: 3vw;
                margin-bottom: 4%;
            }
        }

        input {
            font-size: 2.5vw;
        }

        button {
            font-size: 3vw;
        }
    }

    .blog-category {
        .blog-category-present {
            .b-p {
                width: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                p,
                button {
                    font-size: 3vw
                }


            }
        }
    }

    .o-f-s {
        form {
            .input {
                flex-direction: column;

                label {
                    width: 100%;
                    font-size: 3vw;
                    margin-bottom: 2%;
                    margin-top: 3%;
                }

                input {
                    width: 100%;
                    font-size: 2.5vw;
                }

                .job {
                    font-size: 3vw;
                    width: 100%;
                }

                select {
                    width: 100%;
                    font-size: 2.5vw;
                }
            }
        }
    }

    .student-edit {
        .student-edit-card {
            width: calc(100% / 2.1);

            .student-edit-card-text {
                p {
                    font-size: 3.5vw;
                }

                h3 {
                    font-size: 3vw;
                }

                ul {
                    li {
                        font-size: 2.5vw;
                    }
                }
            }

            .student-edit-card-button {

                button,
                a {
                    font-size: 2.5vw
                }
            }
        }
    }

    .ql-container.ql-snow {
        height: 70%;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 20px;
        width: 22px;
        margin-top: 0;
    }

    .admin-settings {

        input,
        button {
            font-size: 3vw;
        }
    }

    .contact-form {
        table {
            margin: 5% 0;

            thead {
                tr {
                    th {
                        font-size: 3vw;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .admin-login {
        padding: 15% 5%;

        .admin-login-content {

            form {
                width: 50%;
                img {
                    width: 50%;
                }

                h5 {
                    font-size: 5.5vw;
                }

                input {
                    font-size: 3.5vw;
                }

                .login-button {
                    font-size: 4vw;
                }
            }
        }
    }

    .dashboard {
        flex-direction: column;
    }

    .dashboard-nav {
        height: 10vh;
        width: 100%;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        z-index: 69999;

        .menu {
            display: block;
        }

        .a-logo {
            padding: 2% 3%;
            width: 30%;
            margin: auto 0;
        }

        .dashboard-nav-list {
            display: none;
        }

        .dashboard-nav-mobile-list {
            display: block;
            margin: 0;
            width: 70%;
            height: 100%;
            padding: 0 5%;
            position: fixed;
            right: 0;
            top: 0;
            background: #fff;
            z-index: 69999;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

            .close {
                fill: $blue;
                margin-left: auto;
                position: absolute;
                right: 5%;
                top: 2%;
                display: block;
                width: 30px;
                height: 30px;

            }

            .dashboard-nav-item {
                font-size: 3vw;
                color: $blue;

                svg {
                    fill: $blue;
                }
            }

            button {
                border-top: 1px solid $blue;

                p {
                    color: $blue;
                    font-size: 4vw;
                }

                svg {
                    fill: $blue;

                }
            }
        }

        .bd {
            margin-top: 30%;
        }

        .admin-blog {
            color: $blue;
            font-size: 3vw;
            display: block;

            svg {
                fill: $blue;
            }



            .admin-blog-nav {
                display: none;
                color: $blue;
                height: 15%;
                border-top: 1px solid $blue;

                a {
                    font-size: 2.5vw;

                }
            }
        }


    }

    .dashboard-app {
        width: 100%;
        margin-top: 25%;

        h3 {
            font-size: 5vw;
            font-weight: bold;
            color: $blue;
        }
    }

    .blog-list {
        .blog-card {
            width: calc(100% / 2.1);

            .blog-card-text {
                p {
                    font-size: 2.5vw;
                }

                span {
                    font-size: 2.5vw;
                }
            }

            .blog-card-button {

                a,
                button {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .blog-create form {
        .__input {
            flex-direction: column;

            label {
                font-size: 3vw;
                width: 100%;
                margin-bottom: 2%;
            }

            .checkbox-buttons {
                .checkbox-body {
                    label {
                        font-size: 2vw;
                    }
                }
            }

            .b__input {
                span {
                    font-size: 1.8vw;
                }
            }

            select {
                font-size: 3vw;
                margin-bottom: 4%;
            }
        }

        input {
            font-size: 2.5vw;
        }

        button {
            font-size: 3vw;
        }
    }

    .blog-category {
        .blog-category-present {
            .b-p {
                width: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                p,
                button {
                    font-size: 3vw
                }


            }
        }
    }

    .o-f-s {
        form {
            .input {
                flex-direction: column;

                label {
                    width: 100%;
                    font-size: 3vw;
                    margin-bottom: 2%;
                    margin-top: 3%;
                }

                input {
                    width: 100%;
                    font-size: 2.5vw;
                }

                .job {
                    font-size: 3vw;
                    width: 100%;
                }

                select {
                    width: 100%;
                    font-size: 2.5vw;
                }
            }
        }
    }

    .student-edit {
        .student-edit-card {
            width: calc(100% / 2.1);

            .student-edit-card-text {
                p {
                    font-size: 3.5vw;
                }

                h3 {
                    font-size: 3vw;
                }

                ul {
                    li {
                        font-size: 2.5vw;
                    }
                }
            }

            .student-edit-card-button {

                button,
                a {
                    font-size: 2.5vw
                }
            }
        }
    }

    .ql-container.ql-snow {
        height: 70%;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 20px;
        width: 22px;
        margin-top: 0;
    }

    .admin-settings {

        input,
        button {
            font-size: 3vw;
        }
    }

    .contact-form {
        table {
            margin: 5% 0;

            thead {
                tr {
                    th {
                        font-size: 3vw;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) {
    .admin-login {
        padding: 5%;

        .admin-login-content {
            // width: 60%;

            form {
                width: 50%;
                h5 {
                    font-size: 3vw;
                }

                input,
                .login-button {
                    font-size: 2.5vw;
                }
            }
        }
    }
}