@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';

.login {
    margin-top: 12%;
    position: relative;

    .mascot {
        position: absolute;
        top: -10%;
        right: 15%;
        z-index: 500;
        width: 20%;
    }
}

.container {
    position: relative;
    width: 750px;
    height: 450px;
    border: 2px solid $blue;
    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.6);
    overflow: hidden;
    // background: black;
}


.container .form-box {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.form-box.Login {
    left: 0;
    padding: 0 40px;

    img {
        width: 65%;
        position: absolute;
        top: 3%;
        right: -70%;
    }
}

.form-box.Login .animation {
    transform: translateX(0%);
    transition: .3s;
    opacity: 1;
    transition-delay: calc(.1s * var(--S));
}

.container.active .form-box.Login .animation {
    transform: translateX(-120%);
    opacity: 0;
    transition-delay: calc(.1s * var(--D));
}

.form-box.Register {
    /* display: none; */
    right: 0;
    padding: 0 60px;

    img {
        width: 65%;
        position: absolute;
        top: 3%;
        left: -70%;
        transform: scaleX(-1);
    }
}

.form-box.Register .animation {
    transform: translateX(120%);
    transition: .3s ease;
    opacity: 0;
    filter: blur(10px);
    transition-delay: calc(.1s * var(--S));
}

.container.active .form-box.Register .animation {
    transform: translateX(0%);
    opacity: 1;
    filter: blur(0px);
    transition-delay: calc(.1s * var(--li));
}

.form-box h2 {
    font-size: 32px;
    text-align: center;
}

.form-box .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin-top: 25px;
}

.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: $blue;
    font-weight: 600;
    border-bottom: 2px solid $blue;
    padding-right: 23px;
    transition: .5s;
}

.input-box input:focus,
.input-box input:valid {
    border-bottom: 2px solid $blue;
}

.input-box label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 16px;
    color: $blue;
    transition: .5s;
}

.input-box input:focus~label,
.input-box input:valid~label {
    top: -5px;
    color: $blue;
}

.input-box box-icon {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 18px;
    transform: translateY(-50%);
    color: $blue;
}

.input-box input:focus~box-icon,
.input-box input:valid~box-icon {
    color: $blue;
}

.login-btn {
    position: relative;
    width: 100%;
    height: 45px;
    background: transparent;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid $blue;
    overflow: hidden;
    z-index: 1;
    color: #fff;
}

.login-btn::before {
    content: "";
    position: absolute;
    height: 300%;
    width: 100%;
    // background: linear-gradient(#25252b, $blue, #25252b, $blue);
    background: $blue;
    top: -100%;
    left: 0;
    z-index: -1;
    transition: .2s;
}

.login-btn:hover:before {
    top: 0;
}

.regi-link {
    font-size: 14px;
    text-align: center;
    margin: 20px 0 10px;
}

.regi-link a {
    text-decoration: none;
    color: $blue;
    font-weight: 600;
}

.regi-link a:hover {
    text-decoration: underline;
}

.info-content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.info-content.Login {
    right: 0;
    text-align: right;
    padding: 0 40px 60px 150px;
}

.info-content.Login .animation {
    transform: translateX(0);
    transition: .2s ease;
    transition-delay: calc(.1s * var(--S));
    opacity: 1;
    filter: blur(0px);
}

.container.active .info-content.Login .animation {
    transform: translateX(120%);
    opacity: 0;
    filter: blur(10px);
    transition-delay: calc(.1s * var(--D));
}

.info-content.Register {
    /* display: none; */
    left: 0;
    text-align: left;
    padding: 0 150px 60px 38px;
    pointer-events: none;
}

.info-content.Register .animation {
    transform: translateX(-120%);
    transition: .2s ease;
    opacity: 0;
    filter: blur(10PX);
    transition-delay: calc(.1s * var(--S));
}

.container.active .info-content.Register .animation {
    transform: translateX(0%);
    opacity: 1;
    filter: blur(0);
    transition-delay: calc(.1s * var(--li));
}

.info-content h2 {
    text-transform: uppercase;
    font-size: 36px;
    line-height: 1.3;
}

.info-content p {
    font-size: 16px;
}

.container .curved-shape {
    position: absolute;
    right: 0;
    top: -5px;
    height: 600px;
    width: 850px;
    background: linear-gradient(45deg, #25252b, $blue);
    background: $blue;
    /*transform: rotate(10deg) skewY(40deg);*/
    transform: rotate(10deg) skewY(40deg);
    transform-origin: bottom right;
    transition: .3s ease;
    transition-delay: .3s;
}

.container.active .curved-shape {
    transform: rotate(0deg) skewY(0deg);
    transition-delay: .3s;
}

.container .curved-shape2 {
    position: absolute;
    left: 250px;
    top: 100%;
    height: 700px;
    width: 850px;
    background: #fff;
    border-top: 3px solid $blue;
    transform: rotate(0deg) skewY(0deg);
    transform-origin: bottom left;
    transition: .3s ease;
    transition-delay: .3s;
}

.container.active .curved-shape2 {
    transform: rotate(-11deg) skewY(-41deg);
    transition-delay: .3s;
}

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .login {
        margin-top: 30%;

        .container {
            width: 340px;
            // overflow: visible;
            margin: 0;
            margin-left: 5%;

            .curved-shape {
                display: none;
            }

            .curved-shape2 {
                display: none;
            }

            .Login {
                width: 60%;
                padding: 2% 5%;

                img {
                    top: 20%;
                    right: -60%;
                    // display: none !important;
                }
            }

            .Register {
                width: 60%;
                padding: 2% 5%;

                img {
                    top: 20%;
                    left: -60%;
                    // display: none !important;
                }
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .login {
        margin-top: 30%;

        .container {
            width: 550px;
            // overflow: visible;
            margin: 0;
            margin-left: 5%;

            .curved-shape {
                display: none;
            }

            .curved-shape2 {
                display: none;
            }

            .Login {
                width: 60%;
                padding: 2% 5%;

                img {
                    top: 20%;
                    right: -60%;
                    // display: none !important;
                }
            }

            .Register {
                width: 60%;
                padding: 2% 5%;

                img {
                    top: 20%;
                    left: -60%;
                    // display: none !important;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .login {
        margin-top: 30%;

        .container {
            width: 450px;
            // overflow: visible;
            margin: 0;
            margin-left: 5%;

            .curved-shape {
                display: none;
            }

            .curved-shape2 {
                display: none;
            }

            .Login {
                width: 60%;
                padding: 2% 5%;

                img {
                    top: 10%;
                    right: -60%;
                    // display: none !important;
                }
            }

            .Register {
                width: 60%;
                padding: 2% 5%;

                img {
                    top: 10%;
                    left: -60%;
                    // display: none !important;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) {
    .login{
        margin-top: 15%;
    }
}

@media (min-width: 1025px) and (max-width: $xl) {}