@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';


// Footer =============================
footer {
    padding: 2% 2% 0 2%;
    font-family: $body-font;
    position: relative;
    margin-top: 5%;
    // background: $blue;
    // background: rgb(32, 88, 212);
    // background: linear-gradient(202deg, rgba(32, 88, 212, 1) 0%, rgba(1, 5, 15, 0.8408789541207108) 100%);
    background: #fff;
    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);

    .last {
        color: rgba($color: #fff, $alpha: 0.8);
        font-size: 0.9vw;
        text-align: justify;
        margin: 0;
        padding-bottom: 2%;

    }

    .footer-body {
        @include flex;
        color: #000;

        .footer-about,
        .footer-links .footer-map {
            margin: 1%;

        }

        .footer-about {
            width: 30%;
            // background: #fff;
            padding: 0 1% 1% 1%;

            .footer-about-text {
                margin-top: 10%;

                h2 {
                    font-size: 20px;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                    margin-bottom: 1%;
                    color: $green;
                    font-weight: bold;
                }

                p {
                    font-size: 16px;
                    // color: rgba($color: #fff, $alpha: 0.8);
                    text-align: justify;
                    margin: 0;
                }
            }

            .footer-about-logo {
                width: 100%;
                margin-left: -1%;
                margin-top: -2%;

                img {
                    width: 60%;
                    background: #fff;
                    padding: 2%;
                }
            }

        }

        .footer-links {
            width: 20%;
            padding: 1% 2%;

            h4 {
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 10%;
                font-family: $title-font;
                font-weight: bold;
                // text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.8);
                position: relative;
            }

            .footer-links-content {
                display: flex;
                flex-direction: column;


                a {
                    font-size: 1vw;
                    text-transform: capitalize;
                    margin-bottom: 3%;

                    &:hover {
                        color: rgba($color: $blue, $alpha: 0.8);
                        transform: translatex(5px);
                        transition: .5s all ease;
                    }

                    svg {
                        width: 15px;
                        height: 15px;

                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }

        .footer-contact {
            width: 30%;
            // background: rgba($color: #fff, $alpha: 0.2);
            // backdrop-filter: blur(5px);
            padding: 1% 2%;
            // border-radius: 20px;
            // box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
            // border: 1px solid rgba($color: #fff, $alpha: 0.1);

            .footer-follow {
                margin: 10% 0;
            }

            h4 {
                font-size: 1.5vw;
                text-transform: capitalize;
                margin-bottom: 8%;
                font-family: $title-font;
                font-weight: bold;
                text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.8);
            }

            .footer-contact-body {

                .footer-contact-content {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2%;

                    svg {
                        margin-right: 5%;
                        width: 15px;
                        height: 15px;


                        path {
                            fill: #fff;
                        }
                    }

                    a {
                        font-size: 1vw;
                        text-transform: capitalize;


                    }

                    &:nth-child(2) {
                        a {
                            text-transform: lowercase;
                        }
                    }
                }

            }


        }

        .footer-map {
            width: 30%;

            h4 {
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 5%;
                font-family: $title-font;
                font-weight: bold;
                // text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.8);
                position: relative;
            }

            .footer-follow-top {

                display: flex;
                justify-content: flex-start;
                align-items: center;

                a {
                    margin-right: 3%;
                    border: 1px solid #fff;
                    padding: 2%;
                    border-radius: 5px;
                    width: 35px;
                    height: 35px;
                    position: relative;
                    // background: #fff;
                    border: 1px solid $blue;

                    svg {
                        position: absolute;
                        top: 50%;

                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 20px;
                        height: 20px;
                        fill: #fff;
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        // filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 20px;
                        height: 20px;
                        fill: #fff;
                    }

                    &:hover {
                        background: #fff;
                        transition: .2s all ease;

                        svg {
                            fill: $blue;
                        }
                    }
                }
            }

            iframe {
                width: 100%;
                // height: 70%;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
                border: 2px solid #fff;
                // border-radius: 10px;
                margin-top: 5%;
                flex-grow: 1;
            }
        }
    }

    .footer-copyright {
        @include flex;
        border-top: 1px solid rgba($color: #000, $alpha: 0.3);
        color: #000;
        justify-content: center;
        padding-top: 1%;

        p {
            font-size: 16px;

            a {
                font-weight: bold;
            }
        }

        .footer-copyright-content {
            width: 30%;
            color: #000;

            a {
                font-size: 1vw;
                margin-right: 5%;
            }
        }
    }

    .amfi {

        h4 {
            text-align: center;
            font-size: 20px;
            color: $green;
        }

        .amfi-body {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2%;

            .amfi-card {
                width: calc(100% / 5);
                text-align: center;

                h6 {
                    font-size: 14px;
                }

                p {
                    font-size: 12px;
                }
            }
        }
    }
}

// Footer ends ========================



// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    footer {
        .footer-body {
            flex-direction: column;

            .footer-about,
            .footer-links,
            .footer-map {
                width: 100%;
            }

            .footer-links {
                h4 {
                    margin-bottom: 5%;
                }

                .footer-links-content {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    footer {
        .footer-body {
            flex-direction: column;

            .footer-about,
            .footer-links,
            .footer-map {
                width: 100%;
            }

            .footer-links {
                h4 {
                    margin-bottom: 5%;
                }

                .footer-links-content {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    footer {
        .footer-body {
            flex-direction: column;

            .footer-about,
            .footer-links,
            .footer-map {
                width: 100%;
            }

            .footer-links {
                h4 {
                    margin-bottom: 5%;
                }

                .footer-links-content {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {
    footer .footer-body .footer-links .footer-links-content a {
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {
    footer .footer-body .footer-links .footer-links-content a {
        font-size: 16px;
    }
}

@media (min-width: 1025px) and (max-width: $xl) {
    footer .footer-body .footer-links .footer-links-content a {
        font-size: 16px;
    }
}