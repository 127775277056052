@import 'variables.scss';
@import 'mixin.scss';
@import 'animations.scss';


/*===============================================================
# Common
===============================================================*/

// * {
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -khtml-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

* {
    transition: .5s all ease-in-out;
}

a {
    color: inherit;
    text-decoration: none;
}

li {
    list-style-type: none;
}

p,
a,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $body-font;
}



.four {
    margin: 5% 0;

    h3 {
        text-align: center;
        padding-bottom: 0.7em;
        position: relative;
        text-transform: capitalize;
        font-size: 40px;
        color: $blue;
        font-weight: bold;

        em {
            font-style: normal;
            font-weight: bold;
            color: $green;
        }

        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 60px;
            height: 2px;
            content: "";
            left: 50%;
            margin-left: -30px;
            background-color: $green;
        }
    }
}



$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .four {

        h3 {
            font-size: 25px;
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .four {

        h3 {
            font-size: 25px;
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .four {

        h3 {
            font-size: 25px;
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}