@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';
@import "~react-image-gallery/styles/css/image-gallery.css";

.about {
  padding: 5%;
  margin-top: 5%;

  .about-body {

    img {
      float: right;
      width: 40%;
      margin: 0 0 2% 2%;
    }

    p {
      font-size: 20px;
      line-height: 200%;
    }
  }

  .about-mission {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5% 0;

    .about-mission-text {
      width: 40%;
      margin-right: 5%;

      h3 {
        text-transform: capitalize;
        font-size: 60px;
        margin-bottom: 5%;
        font-weight: bold;
        color: $blue;

      }

      p {
        font-size: 20px;
      }
    }

    img {
      width: 50%;
    }
  }

  .statistics {
    .statistics-body {
      display: flex;
      justify-content: center;
      align-items: center;

      .statistics-card {
        width: calc(100% / 3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin-left: 2%;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
        padding: 1%;
        // background: $blue;
        // color: #fff;
        border: 1px solid $blue;

        &:first-child {
          margin-left: 0;
        }

        img {
          width: 50%;
          filter: drop-shadow(2px 1px 1px rgba(0, 0, 0, 0.7));
        }

        .statistics-card-text {
          // width: 60%;
          margin: 5% auto;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          h3 {
            font-size: 60px;
            font-weight: bold;
            color: $blue;
            text-shadow: 0px 1px 1px #000;
          }

          span {
            margin-right: 3%;
          }

          p {
            font-size: 30px;
            color: $green;
            font-weight: bold;
          }
        }

      }
    }
  }


  .team-area {
    padding: 0 5%;

    .team-items {
      &:last-child {
        margin-top: 2%;
      }
    }

    .item {
      border: 2px solid $blue;

      .thumb {
        position: relative;
        overflow: hidden;
        z-index: 1;

        &::after {
          background: #232323 none repeat scroll 0 0;
          content: "";
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: all 0.35s ease-in-out;
          -webkit-transition: all 0.35s ease-in-out;
          -moz-transition: all 0.35s ease-in-out;
          -ms-transition: all 0.35s ease-in-out;
          -o-transition: all 0.35s ease-in-out;
          width: 100%;
        }

        img {
          -webkit-transition: all 0.35s ease-in-out;
          -moz-transition: all 0.35s ease-in-out;
          -ms-transition: all 0.35s ease-in-out;
          -o-transition: all 0.35s ease-in-out;
          transition: all 0.35s ease-in-out;
          width: 65%;
          display: block;
          margin: 10% auto;
          filter: grayscale(100%);
          border-radius: 50%;
          border: 5px solid $blue;

        }
      }
    }

    .info {
      background: #ffffff none repeat scroll 0 0;
      -moz-box-shadow: 0 0 10px #cccccc;
      -webkit-box-shadow: 0 0 10px #cccccc;
      -o-box-shadow: 0 0 10px #cccccc;
      box-shadow: 0 0 10px #cccccc;
      padding: 40px 20px 20px;
      position: relative;
      text-align: center;
      z-index: 9;

      h4 {
        font-weight: 600;
        margin-bottom: 5px;
        text-transform: capitalize;
      }

      span {
        color: $blue;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
      }

      .message {
        height: 50px;
        line-height: 40px;
        margin-left: -25px;
        margin-top: -25px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 50px;

        a {
          background: #fff none repeat scroll 0 0;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 10px;
          -moz-box-shadow: 0 0 10px #cccccc;
          -webkit-box-shadow: 0 0 10px #cccccc;
          -o-box-shadow: 0 0 10px #cccccc;
          box-shadow: 0 0 10px #cccccc;
          box-sizing: border-box;
          color: #ff5a6e;
          display: inline-block;
          font-size: 20px;
          height: 50px;
          line-height: 50px;
          width: 50px;

          svg {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }

  .life {
    // padding: 0 5%;

    .life-body {

      // background: $blue;
      // margin: auto;
      // width: 60%;
      // .image-gallery-slide.image-gallery-center{
      //   height: 60vh;
      // }

      // .image-gallery-content {
      //   display: flex;
      //   flex-direction: row-reverse;
      //   height: 70vh;
      // }

      // .image-gallery-content {
      //   display: flex;
      //   flex-direction: row-reverse;
      // }

      // .image-gallery-thumbnails .image-gallery-thumbnails-container {
      //   display: flex;
      //   flex-direction: column;
      // }

      // .image-gallery-slide-wrapper {
      //   width: 90%;
      // }

      // .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
      //   width: 10%;
      // }

      .image-gallery-thumbnail .image-gallery-thumbnail-image {
        height: 10vh;
        width: 100%;
        object-fit: cover;
      }

      .life-card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        img {
          width: calc(100% / 3.1);
          margin: .5%;
          border-radius: 10px;
        }
      }
    }
  }

  .wise-group {
    margin-top: 5%;

    .wise-group-body {
      @include flex;
      flex-wrap: wrap;

      a {
        margin: 1%;
        width: calc(100% /3.2);

        img {
          width: 100%;
          box-shadow: 2px 2px 15px rgba($color: #000, $alpha: 0.2);

          &:hover {
            transform: scale(1.1);
            transition: .8s all ease;
          }
        }
      }
    }
  }

}


// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
  .about {
    margin-top: 20%;

    .about-body {
      p {
        font-size: 16px;
      }

      img {
        width: 50%;
      }
    }

    .about-mission {
      flex-direction: column;

      .about-mission-text {
        width: 100%;

        h3 {
          font-size: 25px;
        }

        p {
          font-size: 16px;
        }
      }

      #bannerVideo {
        width: 100%;
      }
    }

    .statistics {
      .statistics-body {
        flex-direction: column;

        .statistics-card {
          width: 100%;
          margin-bottom: 5%;

          .statistics-card-text {
            h3 {
              font-size: 40px;
            }

            p {
              font-size: 20px;
            }
          }
        }
      }
    }

    .team-area {
      .item {
        margin-bottom: 5%;
      }
    }

    .wise-group {
      .wise-group-body {
        // flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 5%;

        a {
          width: calc(100% / 2.1);

          img {
            padding: 2%;
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
  .about {
    margin-top: 15%;

    .about-body {
      p {
        font-size: 16px;
      }

      img {
        width: 50%;
      }
    }

    .about-mission {
      flex-direction: column;

      .about-mission-text {
        width: 100%;

        h3 {
          font-size: 25px;
        }

        p {
          font-size: 16px;
        }
      }

      #bannerVideo {
        width: 100%;
      }
    }

    .statistics {
      .statistics-body {
        // flex-direction: column;

        .statistics-card {
          // width: 100%;
          margin-bottom: 5%;

          .statistics-card-text {
            h3 {
              font-size: 30px;
            }

            p {
              font-size: 16px;
            }
          }
        }
      }
    }

    .team-area {
      .item {
        margin-bottom: 5%;
      }
    }

    .wise-group {
      .wise-group-body {
        // flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 5%;

        a {
          width: calc(100% / 2.1);

          img {
            padding: 2%;
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
  .about {
    margin-top: 15%;

    .about-body {
      p {
        font-size: 16px;
      }

      img {
        width: 50%;
      }
    }

    .about-mission {
      flex-direction: column;

      .about-mission-text {
        width: 100%;

        h3 {
          font-size: 25px;
        }

        p {
          font-size: 16px;
        }
      }

      #bannerVideo {
        width: 100%;
      }
    }

    .statistics {
      .statistics-body {
        flex-direction: column;

        .statistics-card {
          width: 100%;
          margin-bottom: 5%;

          .statistics-card-text {
            h3 {
              font-size: 40px;
            }

            p {
              font-size: 20px;
            }
          }
        }
      }
    }

    .team-area {
      .item {
        margin-bottom: 5%;
      }
    }

    .wise-group {
      .wise-group-body {
        // flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 5%;

        a {
          width: calc(100% / 2.1);

          img {
            padding: 2%;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: $lg) {
  .about {
    // margin-top: 15%;

    .about-body {
      p {
        font-size: 16px;
      }

      img {
        width: 50%;
      }
    }

    .about-mission {
      // flex-direction: column;

      .about-mission-text {
        width: 60%;

        h3 {
          font-size: 25px;
        }

        p {
          font-size: 16px;
        }
      }

      #bannerVideo {
        width: 40%;
      }
    }

    .statistics {
      .statistics-body {
        // flex-direction: column;

        .statistics-card {
          // width: 100%;
          margin-bottom: 5%;

          .statistics-card-text {
            h3 {
              font-size: 35px;
            }

            p {
              font-size: 23px;
            }
          }
        }
      }
    }

    .team-area {
      .item {
        margin-bottom: 5%;
      }

      .info {
        padding: 30px 5px;

        span {
          text-transform: capitalize;
          font-size: 13px;
        }


      }
    }

  }
}

@media (min-width: 1025px) and (max-width: $xl) {}