@import "../../Sass/variables.scss";
@import "../../Sass/mixin.scss";
@import "../../Sass/animations.scss";

.investment-philosophy {
    padding: 0 5%;
    // background: $blue-transparent-gradient;

    .page-title {
        display: none;
    }

    .title {
        h4 {
            color: #000;
        }
    }

    p {
        text-align: center;
    }

    .investment-content {
        @include flex;
        margin: 5% 0;


        .investment-philosophy-body {
            width: 40%;
            @include flex;
            flex-wrap: wrap;
            text-align: center;



            .i-p-card {
                width: calc(100% /2.2);
                margin: 1%;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
                padding: 3% 1%;
                background: #fff;
                @include flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid $blue;

                &:hover {
                    border: 2px solid $blue;
                    transition: .5s all ease;

                    .i-img {
                        transform: scale(1.3) rotate(4deg);
                        transition: .5s all ease;

                    }
                }

                h4 {
                    font-size: 1vw;
                    text-transform: capitalize;
                }

                .i-img {
                    padding: 5%;

                    img {
                        width: 50px;
                        height: 50px;
                        fill: $blue;
                        object-fit: contain;
                    }
                }
            }
        }

        .child {
            width: 60%;

            div {
                height: 97%;
                background: $blue;
                color: #fff;
                text-align: center;
                padding: 5% 10%;
                margin: 1%;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.9);
                @include flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h4 {
                    font-weight: bold;
                    text-transform: capitalize;
                    margin-bottom: 5%;
                    font-size: 3vw;
                }

                p {
                    font-size: 1.3vw;
                    text-align: justify;
                }

                a {
                    background: #fff;
                    color: $blue;
                    font-weight: bold;
                    padding: 2% 5%;
                    text-align: center;
                    box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.9);
                    margin-top: 5%;
                    border-radius: 10px;

                    &:hover {
                        transform: scale(1.1);
                    }
                }

            }

        }
    }

}

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .investment-philosophy {
        .investment-content {
            flex-direction: column;

            .investment-philosophy-body {
                width: 100%;

                .i-p-card {
                    width: calc(100% / 3.2);

                    h4 {
                        font-size: 14px;
                    }
                }
            }

            .child {
                width: 100%;

                div {
                    h4 {
                        font-size: 20px;
                    }

                    p {
                        font-size: 16px;
                    }

                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .investment-philosophy {
        .investment-content {
            flex-direction: column;

            .investment-philosophy-body {
                width: 100%;

                .i-p-card {
                    width: calc(100% / 3.2);

                    h4 {
                        font-size: 14px;
                    }
                }
            }

            .child {
                width: 100%;

                div {
                    h4 {
                        font-size: 20px;
                    }

                    p {
                        font-size: 16px;
                    }

                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .investment-philosophy {
        .investment-content {
            flex-direction: column;

            .investment-philosophy-body {
                width: 100%;

                .i-p-card {
                    width: calc(100% / 3.2);

                    h4 {
                        font-size: 14px;
                    }
                }
            }

            .child {
                width: 100%;

                div {
                    h4 {
                        font-size: 20px;
                    }

                    p {
                        font-size: 16px;
                    }

                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) {
    .investment-philosophy {
        .investment-content {

            .investment-philosophy-body {

                .i-p-card {

                    h4 {
                        font-size: 16px;
                    }
                }
            }

            .child {

                div {
                    p {
                        font-size: 16px;
                    }

                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: $xl) {}