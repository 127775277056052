@import "../../Sass/mixin.scss";
@import "../../Sass/variables.scss";
@import "../../Sass/animations.scss";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


.calculators {
    padding: 5%;
    margin-top: 5%;

    .calculators-body {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        .calculators-card {
            width: calc(100% / 3.2);
            padding: 2% 1%;
            background: #fff;
            box-shadow: 2px 2px 5px rgba(1, 5, 15, 0.6);
            margin: 1%;
            position: relative;
            display: flex;
            align-items: center;
            border: 1px solid rgba($color: $blue, $alpha: 0.4);
            border-radius: 5px;

            &:hover {
                border: 1px solid $blue;
            }

            img {
                width: 30%;
            }

            .calculator-text {
                width: 70%;
                margin-left: 5%;

                h3 {
                    text-transform: capitalize;
                    padding: 2%;
                    border-radius: 5px;
                    color: $blue;
                    font-weight: bold;
                    font-size: 25px;
                }
            }


        }
    }
}

.sip {
    padding: 5% 5% 0 5%;
    margin-top: 2%;

    // input::-webkit-outer-spin-button,
    // input::-webkit-inner-spin-button {
    //     -webkit-appearance: none;
    //     margin: 0;
    // }

    // input[type="range"] {
    //     /* removing default appearance */
    //     -webkit-appearance: none;
    //     appearance: none;
    //     /* creating a custom design */
    //     width: 100%;
    //     cursor: pointer;
    //     outline: none;
    //     /*  slider progress trick  */
    //     overflow: hidden;
    //     border-radius: 16px;
    // }

    // /* Track: webkit browsers */
    // input[type="range"]::-webkit-slider-runnable-track {
    //     height: 15px;
    //     background: rgba($color: $blue, $alpha: 0.4);
    //     border-radius: 16px;
    // }

    // /* Track: Mozilla Firefox */
    // input[type="range"]::-moz-range-track {
    //     height: 15px;
    //     background: #ccc;
    //     border-radius: 16px;
    // }

    // /* Thumb: webkit */
    // input[type="range"]::-webkit-slider-thumb {
    //     /* removing default appearance */
    //     -webkit-appearance: none;
    //     appearance: none;
    //     /* creating a custom design */
    //     // height: 15px;
    //     // width: 15px;
    //     background-color: #fff;
    //     border-radius: 50%;
    //     border: 2px solid $blue;
    //     margin-top: 1px;
    //     /*  slider progress trick  */
    //     box-shadow: -407px 0 0 400px $blue;
    // }


    // /* Thumb: Firefox */
    // input[type="range"]::-moz-range-thumb {
    //     height: 15px;
    //     width: 15px;
    //     background-color: #fff;
    //     border-radius: 50%;
    //     border: 1px solid $blue;
    //     /*  slider progress trick  */
    //     box-shadow: -407px 0 0 400px $blue;
    // }

    .sip-body {
        display: flex;
        margin-bottom: 5%;
        // margin-top: 0;

        .sip-left {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-right: 3%;


            label {
                margin: 1%;
                font-weight: 500;
                margin-top: 4%;
                font-size: 16px;
                color: rgba($color: #000000, $alpha: 0.9);
            }

            .sip-left-card {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                margin-left: 10px;
                justify-content: space-between;
                // margin-bottom: 2%;

                
                input[type=number] {
                    width: 30%;
                    margin: 0;
                    text-align: left;
                    color: $green;
                    font-size: 18px;
                    font-weight: bold;
                    padding: 5px 30px;
                    background: rgba($color: $green, $alpha: 0.1);
                    border-radius: 20px;
                }

                p{
                    color: red;
                }

                input {
                    width: 65%;
                    margin-right: 2%;
                }

                label {
                    margin: 0;
                }
            }
        }

        .sip-right {
            width: 50%;
            padding: 3% 5%;
            border-radius: 20px;
            margin-left: 2%;
            // border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
            // box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.7);

            .result {
                // display: flex;
                // align-items: stretch;
                // justify-content: center;
                flex-wrap: wrap;
            }

            p {
                // position: relative;
                font-size: 20px;
                // text-align: center;
                // width: calc(100%/3.2);
                // box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.6);
                // border-radius: 10px;
                // padding: 10px;
                margin: 0;
                margin-bottom: 2%;
                display: flex;
                flex-direction: row;
                // align-items: center;
                justify-content: flex-start;
                font-weight: bold;

                span {
                    color: $blue;
                    font-size: 20px;
                    margin-left: 3%;
                }
            }

            .main {
                box-shadow: none;
                font-size: 30px;
                text-align: left;
                width: 100%;
                flex-direction: column;
                // margin-bottom: 5%;
                font-weight: bold;
                justify-content: flex-start;

                span {
                    font-size: 60px;
                    margin-left: 0;
                }

                sup {
                    font-size: 30px;
                    margin-right: 2%;
                    color: $blue;
                }
            }

            label {
                color: $blue;
                margin-bottom: 0;
                font-size: 18px;
            }

        }



        input,
        select {
            border: none;
            outline: none;
        }

        // input[type=range] {
        //     color: rgba($color: $blue, $alpha: 0.4);
        //     -webkit-appearance: none;
        //     /* Hides the slider so that custom slider can be made */
        //     background: transparent;
        //     /* Otherwise white in Chrome */

        // }

        // input[type=range]:focus {
        //     outline: none;
        //     /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
        // }

        // input[type=range]::-ms-track {
        //     width: 100%;
        //     cursor: pointer;

        //     /* Hides the slider so custom styles can be added */
        //     background: transparent;
        //     border-color: transparent;
        //     color: transparent;
        // }

        // input[type=range]::-webkit-slider-thumb {
        //     // -webkit-appearance: none;
        //     border: 1px solid $blue;
        //     // // height: 16px;
        //     // // width: 16px;
        //     // border-radius: 50%;
        //     background: #ffffff;
        //     // cursor: pointer;
        //     // // margin-top: px;
        //     // /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        //     box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        //     /* Add cool effects to your sliders! */
        // }

        button {
            background: $blue;
            color: #fff;
            font-weight: bold;
            border: none;
            box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
            padding: 2% 5%;
            text-align: center;
            font-size: 18px;
            border-radius: 10px;
            margin: 5% 1% 1% 1%;
            outline: none;
        }
    }

    .sip-table {
        margin-bottom: 5%;
        margin-top: 5%;
        height: 50vh;
        overflow: scroll;


        table {
            width: 100%;
        }

        thead {
            position: sticky;
            top: 0;
            background: $blue;
            border: 1px solid #fff;


            td {
                border: 1px solid #fff;
                padding: 3px 7px;
                font-size: 14px;
                text-align: center !important;
                font-weight: bold;
                color: #fff !important;
            }
        }

        tr {
            td {
                font-size: 14px;
                color: rgba($color: #000000, $alpha: 0.8);
                text-align: right;
                border: 1px solid #000;
                padding: 0 10px;
            }
        }
    }

    .swp {
        tr {
            td {
                text-align: center;
            }
        }
    }
}

.income {
    .sip-body {
        .accordion-button {
            background: rgba($color: $green, $alpha: 0.8);
        }

        .sip-left {
            label {
                span {
                    font-weight: bold;
                    color: $blue;
                }
            }

            select {
                background: rgba($color: $green, $alpha: 0.2);
                padding: 5px 10px;
                border-radius: 20px;
                margin-bottom: 3%;
            }

            .sip-left-card {
                flex-direction: row;
                align-items: center;
                margin-bottom: 1%;
                margin-left: 0;

                label {
                    margin-top: 2%;
                }
            }
        }

        .sip-right {
            .sip-right-body {
                display: flex;

                .sip-right-card {
                    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
                    padding: 5%;
                    margin-right: 1%;
                    width: calc(100% / 2);

                    p {
                        font-size: 16px;
                        display: block;

                        span {
                            font-size: 16px;

                        }

                        &:first-child,
                        &:nth-child(2) {
                            text-align: center;
                            color: $blue;
                            font-size: 25px;
                            margin-bottom: 10%;
                        }
                    }
                }
            }
        }
    }
}

.sip-bottom {
    display: flex;
    justify-content: space-between;

    .sip-text {
        width: 70%;

        h3 {
            font-size: 25px;
            margin-top: 3%;
            font-weight: 600;
            color: rgba($color: #000000, $alpha: 0.9);

            &:first-child {
                margin-top: 0;
            }
        }

        h5 {
            color: rgba($color: #000000, $alpha: 0.9);
            margin-top: 3%;
        }

        ol {
            li {
                list-style-type: unset;
            }
        }

        p,
        li {
            font-size: 18px;
            color: rgba($color: #000000, $alpha: 0.7);
            line-height: 180%;
            list-style-type: disc;
        }

        table {
            width: 100%;

            tr {
                td {
                    border: 1px solid rgba($color: #000000, $alpha: 0.4);
                    color: rgba($color: #000000, $alpha: 0.7);
                    padding: 13px;
                }
            }

        }
    }

    .list {
        width: 25%;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        position: sticky;
        height: 100%;
        right: 0;
        top: 20%;
        // box-shadow: 2px 2px 5px rgba(1, 5, 15, 0.6);
        border: 1px solid rgba($color: $blue, $alpha: 0.4);
        // border-left: 1px solid rgba($color: $blue, $alpha: 0.4);
        // border-top: 1px solid rgba($color: $blue, $alpha: 0.4);

        // border-radius: 5px;
        height: 80vh;
        overflow-y: scroll;

        a {
            text-transform: capitalize;
            padding: 10px 30px;
            color: $blue;
            font-weight: 600;
            margin: 0;
            font-size: 20px;
            border-bottom: 1px solid rgba($color: $blue, $alpha: 0.4);
        }


    }

}

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .calculators {
        margin-top: 15%;

        .calculators-body {
            .calculators-card {
                width: 100%;
                margin-bottom: 3%;

                .calculator-text {
                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .sip {
        margin-top: 15%;

        .sip-body {
            flex-direction: column;
            justify-content: flex-start;

            .sip-left,
            .sip-right {
                width: 100%;
            }

            .sip-left {
                label {
                    margin-bottom: 20px;
                }

                .sip-left-card {
                    flex-direction: column;

                    input[type=number] {
                        width: 50%;
                        margin-bottom: 2%;
                        margin-left: auto;
                    }

                    input[type=range] {
                        width: 100%;
                    }
                }
            }

            .sip-right {
                padding: 0;
                margin-top: 3%;

                .main {
                    font-size: 25px;

                    span {
                        font-size: 40px;
                        margin-bottom: 0;
                    }
                }

                p {
                    font-size: 16px;
                    margin-bottom: 2%;
                }
            }
        }

        .sip-bottom {
            flex-direction: column;

            .sip-text {
                width: 100%;

                h3 {
                    font-size: 20px;
                }

                p,
                li {
                    font-size: 16px;
                }
            }

            .list {
                width: 100%;
                height: 50vh;
            }
        }

        .sip-table {
            overflow-x: scroll;

            tr {

                td {
                    font-size: 12px;
                }
            }
        }

    }

    .income {
        .sip-body {
            .sip-left {
                .sip-left-card {
                    flex-direction: row;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .calculators {
        margin-top: 15%;

        .calculators-body {
            .calculators-card {
                width: calc(100% / 2.1);
                margin-bottom: 3%;

                .calculator-text {
                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .sip {
        margin-top: 15%;

        .sip-body {
            flex-direction: column;
            justify-content: flex-start;

            .sip-left,
            .sip-right {
                width: 100%;
            }

            .sip-left {
                label {
                    margin-bottom: 20px;
                }

                .sip-left-card {
                    flex-direction: column;

                    input[type=number] {
                        width: 50%;
                        margin-bottom: 2%;
                        margin-left: auto;
                    }

                    input[type=range] {
                        width: 100%;
                    }
                }
            }

            .sip-right {
                padding: 0;
                margin-top: 3%;

                .main {
                    font-size: 25px;

                    span {
                        font-size: 40px;
                        margin-bottom: 0;
                    }
                }

                p {
                    font-size: 16px;
                    margin-bottom: 2%;
                }
            }
        }

        .sip-bottom {
            flex-direction: column;

            .sip-text {
                width: 100%;

                h3 {
                    font-size: 20px;
                }

                p,
                li {
                    font-size: 16px;
                }
            }

            .list {
                width: 100%;
                height: 50vh;
            }
        }

        .sip-table {
            overflow-x: scroll;

            tr {

                td {
                    font-size: 12px;
                }
            }
        }

    }

    .income {
        .sip-body {
            .sip-left {
                .sip-left-card {
                    flex-direction: row;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .calculators {
        margin-top: 15%;

        .calculators-body {
            .calculators-card {
                width: 100%;
                margin-bottom: 3%;

                .calculator-text {
                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .sip {
        margin-top: 15%;

        .sip-body {
            flex-direction: column;
            justify-content: flex-start;

            .sip-left,
            .sip-right {
                width: 100%;
            }

            .sip-left {
                label {
                    margin-bottom: 20px;
                }

                .sip-left-card {
                    flex-direction: column;

                    input[type=number] {
                        width: 50%;
                        margin-bottom: 2%;
                        margin-left: auto;
                    }

                    input[type=range] {
                        width: 100%;
                    }
                }
            }

            .sip-right {
                padding: 0;
                margin-top: 3%;

                .main {
                    font-size: 25px;

                    span {
                        font-size: 40px;
                        margin-bottom: 0;
                    }
                }

                p {
                    font-size: 16px;
                    margin-bottom: 2%;
                }
            }
        }

        .sip-bottom {
            flex-direction: column;

            .sip-text {
                width: 100%;

                h3 {
                    font-size: 20px;
                }

                p,
                li {
                    font-size: 16px;
                }
            }

            .list {
                width: 100%;
                height: 50vh;
            }
        }

        .sip-table {
            overflow-x: scroll;

            tr {

                td {
                    font-size: 12px;
                }
            }
        }

    }

    .income {
        .sip-body {
            .sip-left {
                .sip-left-card {
                    flex-direction: row;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) {
    .calculators {
        margin-top: 15%;

        .calculators-body {
            .calculators-card {
                width: calc(100% / 2.1);
                margin-bottom: 3%;

                .calculator-text {
                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

}

@media (min-width: 1025px) and (max-width: $xl) {}