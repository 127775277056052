@import "../../Sass/variables.scss";
@import "../../Sass/mixin.scss";
@import "../../Sass/animations.scss";

.career {
    margin-top: 10%;

    h4 {
        background: $blue;
        padding: 3% 5%;
        color: #fff;
        text-align: center;
        font-size: 30px;

    }

    .career-body {
        padding: 0 5%;

        h3 {
            font-weight: bold;
            text-transform: capitalize;
            text-align: left;
            color: $green;
            margin: 2% 0;
            margin-top: 5%;
        }

        .professionals,
        .students {
            // display: flex;
            // align-items: stretch;

            ul {
                width: 100%;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: stretch;

                li {
                    width: calc(100% / 3);
                    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.6);
                    padding: 2%;
                    margin-right: 1%;
                    font-size: 16px;
                    display: flex;
                    flex-direction: column;
                    border: 1px solid rgba($color: $blue, $alpha: 0.5);

                    svg {
                        width: 70px;
                        height: 70px;
                        fill: $blue;
                        margin: 0 auto 5% auto;

                    }

                    strong {
                        font-size: 18px;
                        color: $blue;
                        margin-bottom: 1%;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            a {
                background: $blue;
                color: #fff;
                padding: 2%;
                // width: 30%;
                // margin-left: 5%;
                font-size: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                // flex-direction: column;
                box-shadow: 5px 5px 5px rgba($color: #000000, $alpha: 0.6);
                // border-radius: 10px;
                // display: none;
                margin-top: 5%;
                border: 2px solid #fff;

                svg {
                    width: 50px;
                    height: 50px;
                    fill: #fff;
                    margin-right: 2%;
                }

                &:hover {
                    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.6);
                    transition: .5s all ease;
                }
            }
        }

    }
}

.c-f-s {
    padding: 5%;

    .c-f-s-body {

        // display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
        .c-f-s-left {
            // width: 20%;
            // padding: 1%;
            // position: sticky;
            // top: 17%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-right: 2%;

            h3 {
                padding: 1% 2%;
                font-size: 25px;
                cursor: pointer;
                text-align: center;
                width: calc(100% /3);
                margin-left: 2%;
                border: 2px solid $blue;
                box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.6);
                background: #fff;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-left: auto;
                    display: none;
                }

                &:hover {
                    color: #fff;
                    transition: .5s all ease-in-out;
                    background: $blue;

                    svg {
                        transform: translate(10px);
                        transition: .5s all ease-in-out;

                        path {
                            fill: $blue;

                        }
                    }
                }
            }

        }

        .c-f-s-right {
            // width: 80%;
            margin-top: 2%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .c-f-s-card {
                width: calc(100% / 3.2);
                box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.6);
                // border: 1px solid $blue;
                padding: 3%;
                margin: 0 2% 2% 0;

                .job-type {
                    color: $blue;
                    text-transform: uppercase;
                    font-weight: bolder;
                    font-size: 20px;
                }

                .job-title {
                    font-size: 30px;
                    margin-bottom: 5%;
                }

                ul {
                    padding: 0;
                    display: flex;

                    li {
                        margin-left: 1%;
                    }
                }

                .p-btn {
                    display: flex;
                    justify-content: space-between;

                    a {
                        border: 1px solid $blue;
                        background: $blue;
                        color: #fff;
                        border-radius: 5px;
                        box-shadow: 2px 2px 5px rgba($color: $blue, $alpha: 0.4);
                        font-size: 18px;
                        padding: 1% 3%;
                        width: 50%;
                        text-align: center;

                        &:last-child {
                            margin-left: 5%;
                        }
                    }
                }


            }

        }
    }
}

.job-form {
    .modal-dialog {
        z-index: 300000 !important;
    }

    .modal-header,
    .modal-footer {
        background: $blue;
        color: #fff;
    }

    .modal-body {
        background: $blue;
    }

    form {
        input {
            // background: transparent;

            &::placeholder {
                text-transform: capitalize;
            }

            &:focus {
                outline: none;
            }
        }

        button {
            background: transparent;
            color: #fff;
            border: 3px solid #fff;

            &:hover {
                color: #000;
                border: 3px solid #fff;
                background: #fff;

            }
        }
    }

}

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .career {
        margin-top: 20%;

        h4 {
            font-size: 20px;
        }

        .career-body {
            h3 {
                margin-bottom: 5%;
            }

            .professionals,
            .students {
                ul {
                    flex-direction: column;

                    li {
                        width: 100%;
                        margin-bottom: 5%;
                        padding: 5%;
                    }
                }
            }
        }
    }

    .c-f-s {
        margin-top: 10%;

        .c-f-s-body {

            .c-f-s-left {
                h3 {
                    font-size: 14px;
                }
            }

            .c-f-s-right {
                flex-direction: column;

                .c-f-s-card {
                    width: 100%;
                    margin-bottom: 5%;
                }
            }
        }

    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .career {
        margin-top: 20%;

        h4 {
            font-size: 20px;
        }

        .career-body {
            h3 {
                margin-bottom: 5%;
            }

            .professionals,
            .students {
                ul {
                    flex-direction: column;

                    li {
                        width: 100%;
                        margin-bottom: 5%;
                        padding: 5%;
                    }
                }
            }
        }
    }

    .c-f-s {
        margin-top: 10%;

        .c-f-s-body {

            .c-f-s-left {
                h3 {
                    font-size: 18px;
                }
            }

            .c-f-s-right {
                // flex-direction: column;

                .c-f-s-card {
                    width: calc(100% / 2.1);
                    margin-bottom: 5%;
                }
            }
        }

    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .career {
        margin-top: 20%;

        h4 {
            font-size: 20px;
        }

        .career-body {
            h3 {
                margin-bottom: 5%;
            }

            .professionals,
            .students {
                ul {
                    flex-direction: column;

                    li {
                        width: 100%;
                        margin-bottom: 5%;
                        padding: 5%;
                    }
                }
            }
        }
    }

    .c-f-s {
        margin-top: 10%;

        .c-f-s-body {

            .c-f-s-left {
                h3 {
                    font-size: 18px;
                }
            }

            .c-f-s-right {
                flex-direction: column;

                .c-f-s-card {
                    width: 100%;
                    margin-bottom: 5%;
                }
            }
        }

    }
}

@media (min-width: 768px) and (max-width: $lg) {
    .career {
        margin-top: 20%;

        h4 {
            font-size: 20px;
        }

        .career-body {
            h3 {
                margin-bottom: 5%;
            }

            .professionals,
            .students {
                ul {
                    // flex-direction: column;
                    align-items: stretch;

                    li {
                        // width: 100%;
                        // margin-bottom: 5%;
                        padding: 5%;
                    }
                }
            }
        }
    }

    .c-f-s {
        margin-top: 10%;

        .c-f-s-body {

            .c-f-s-left {
                h3 {
                    font-size: 18px;
                }
            }

            .c-f-s-right {
                // flex-direction: column;

                .c-f-s-card {
                    // width: 100%;
                    margin-bottom: 5%;

                    .job-title {
                        font-size: 25px;
                    }

                    .p-btn {
                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

    }
}

@media (min-width: 1025px) and (max-width: $xl) {}