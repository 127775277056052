@mixin flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

@mixin centerPosition {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

